@import "../../bower_components/bootstrap/dist/css/bootstrap.css";
@import "../../bower_components/font-awesome/web-fonts-with-css/css/fontawesome-all.css";

@import "partials/fonts";

@import "partials/rules";
body {
  background: #e9ebee;
  font-size: 16px;
  font-family: 'Montserrat';
  padding-bottom: 30px; }
header {
  text-align: center;
  margin-bottom: 100px;
  @media(max-width: 991px) {
    margin-bottom: 20px; }
  .logo {
    max-width: 500px;
    display: block;
    margin: auto;
    color: #2980b9;
    img {
      width: 100%; } } }
.myForm {
  .stage {
    display: none;
    &.active {
      display: block; }
    &-title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      color: #2980b9;
      margin-bottom: 30px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      @media(max-width: 991px) {
        font-size: 20px; }
      &-text1 {
        display: block;
        margin-top: 15px;
        margin-bottom: 15px;
        color: #000000;
        font-size: 25px;
        @media(max-width: 991px) {
          font-size: 18px; } }
      &-text2 {
        display: block; } }
    &-body {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      label {
        cursor: pointer;
        width: 290px;
        text-align: center;
        line-height: 1;
        background: #16a085;
        color: white;
        display: flex;
        height: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        margin: 10px;
        letter-spacing: 0.5px;
        font-weight: 400;
        @media(max-width: 991px) {
          margin: 5px 5px;
          font-size: 14px; }
        &:hover {
          background: #138670; }
        &:active {
          background: #14806b; }
        input {
          display: none; } } }
    &-footer {
      .goBack {
        display: none;
        margin: auto;
        width: 290px;
        height: 45px;
        border-radius: 3px;
        border: none;
        background: #585858;
        color: white;
        font-weight: 500;
        letter-spacing: 1px;
        &:hover {
          background: #6d6d6d; }
        &:active {
          background: #7e7e7e; }
        &.active {
          display: block; } } } }
  .myProgressBar {
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: none;
    &-title {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #2980b9;
      margin-bottom: 20px; }
    .progressBar {
      height: 50px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 20px;
      .ready {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0%;
        background: #16a085;
        position: absolute;
        transition-duration: 0.1s; }
      .percents {
        font-size: 20px;
        position: relative;
        color: black;
        transition-duration: 0.4s; } }
    p {
      font-weight: 500;
      display: none; } }
  &Fields {
    display: none;
    &-title {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 35px;
      @media(max-width: 575px) {
        font-size: 16px; }
      span {
        border-bottom: 1px solid;
        color: #2980b9; }
      div {
        display: block;
        font-size: 16px; } }
    &-fields {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @media(max-width: 991px) {
        flex-direction: column; }
      .field-container {
        width: 100%;
        display: flex;
        margin-right: 30px;
        flex-direction: column;
        @media(max-width: 991px) {
          margin-right: 0;
          margin-bottom: 20px; }
        label {}
        input {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          border: 1px solid #c4c4c4;
          padding: 10px;
          &:hover {
            border: 2px solid rgba(54, 88, 153, 0.53); }
          &:focus {
            border: 2px solid #2980b9; } } }
      button {
        height: 45px;
        flex-shrink: 0;
        width: 150px;
        background: #16a085;
        color: white;
        text-transform: uppercase;
        border-radius: 3px;
        border: 2px solid #16a085;
        box-sizing: border-box;
        letter-spacing: 1px;
        font-weight: bold;
        font-size: 14px; } } }
  .succesPage {
    display: none;
    text-align: center;
    i {
      font-size: 150px;
      color: #16a085;
      margin-bottom: 10px; }
    &-text1 {
      font-weight: 600;
      font-size: 24px;
      @media(max-width: 575px) {
        font-size: 16px; } }
    &-text2 {
      margin-bottom: 15px; }
    .toFacebook {
      font-size: 18px;
      a {
        color: #2980b9;
        font-weight: 600;
        &:hover {
          text-decoration: underline; } } }
    &-text3 {} } }
