input:focus, textarea:focus, button:focus, textarea:focus, a:focus {
  outline: 0; }
a:hover {
  text-decoration: none; }
p,h1,h2,h3,h4,h5,h6,ul,ol,li {
  margin: 0;
  padding: 0;
  list-style: none; }
body {
  font-family: sans-serif;
  margin: auto; }
